<template>
  <div>
    <v-card
      elevation="0"
      rounded="lg"
      class="mb-5"
      :width="width"
      @click="$emit('click')"
      v-show="!min"
    >
      <v-card-title class="ma-0 pa-0" v-if="foto">
        <v-spacer></v-spacer>
        <v-icon
          class="pt-1"
          @click="expandCard = !expandCard"
          v-if="showMinimizar && expandCard"
          size="16"
          >mdi-arrow-collapse</v-icon
        >
        <v-icon
          class="mt-1 mr-1"
          @click="expandCard = !expandCard"
          v-else-if="showMinimizar && !expandCard"
          size="16"
          >mdi-arrow-expand</v-icon
        >
      </v-card-title>

      <v-card-text class="py-2 text-left" :class="foto ? 'pt-0' : 'pt-2'">
        <v-row class="content" no-gutters>
          <v-col>
            <p class="text--secondary">LOTE</p>
          </v-col>
          <v-col align="right">
            <p class="text--secondary">LANCE ATUAL</p>
          </v-col>
        </v-row>
        <v-row class="content" no-gutters>
          <v-col>
            <p class="font-weight-black">{{ lote.numeroLote }}</p>
          </v-col>
          <v-col align="right">
            <p class="font-weight-black">
              R$ {{ lote.info.ultimoLance | formatMoeda }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="showCountdown">
          <v-col cols="6" align="center">
            <span class="text--secondary">TEMPO RESTANTE </span>

            <span v-if="lote && countStatus">{{ countStatus }}</span>
            <count-down
              v-else-if="lote && lote.info.emFechamento && !countStatus"
              :final="lote.info.dataEncerramento"
              :atual="lote.info.dataAtualUtc"
              @FinishCountdown="onFinishCountDown"
            />
          </v-col>
          <v-col :cols="6" align="center">
            <p class="text--secondary">ÚLTIMO LANCE</p>
            {{ lote.info.usuarioUltimoLance }}
          </v-col>
        </v-row>
        <v-row no-gutters v-show="expandCard">
          <v-col class="py-2">
            <transition name="slide" mode="out-in">
              <v-card
                elevation="0"
                rounded="lg"
                width="100%"
                v-show="expandCard"
              >
                <v-img
                  transition="scale-transition"
                  height="120px"
                  :src="lote.foto && lote.foto.arquivo.url"
                ></v-img>
              </v-card>
            </transition>
          </v-col>
        </v-row>
        <v-row class="content" no-gutters>
          <v-col>
            <p class="text--secondary text-truncate mr-2">
              {{ lote.descricao }}
            </p>
          </v-col>
          <v-col align="right">
            <span class="font-weight-bold" :style="`color: ${status.cor}`">
              <v-icon size="14" class="pa-0" dark :color="status.cor"
                >mdi-checkbox-blank-circle</v-icon
              >&nbsp;{{ status.texto }}</span
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-show="min" elevation="0" rounded="lg" class="mb-5" width="100">
      <v-card-text class="py-1 text-center">
        <v-row class="content" no-gutters>
          <v-col>
            <p class="font-weight-black mb-2">{{ lote.numeroLote }}</p>
            <p class="text--secondary" style="font-size: 10px">
              R$ {{ lote.info.ultimoLance || "0" | formatMoeda }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <count-down
        v-if="lote && lote.info.emFechamento && !countStatus"
        :final="lote.info.dataEncerramento"
        :atual="lote.info.dataAtualUtc"
      />
    </v-card>
  </div>
</template>

<script>
import CountDown from "./CountDown.vue";
export default {
  name: "LoteCard",
  props: {
    primeiro: {
      type: Boolean,
      default: false,
    },
    lote: {
      type: Object,
      require: true,
    },
    foto: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    min: {
      type: Boolean,
      default: false,
    },
    showMinimizar: {
      type: Boolean,
      default: true,
    },
    showCountdown: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CountDown,
  },
  data() {
    return {
      expandCard: this.primeiro,
      countStatus: null,
    };
  },
  computed: {
    status() {
      return this.lote.info &&
        (this.lote.info.status.includes("Aberto") ||
          this.lote.info.status.includes("Aguardando"))
        ? { texto: "NA FILA", cor: "#2c75ea" }
        : this.lote.info.status.includes("Removido")
        ? { texto: "REMOVIDO", cor: "#FF826F" }
        : this.lote.info.emFechamento
        ? { texto: "EM LEILÃO", cor: "#F9C718" }
        : this.lote.info.status.includes("Encerrado") &&
          this.lote.info.ultimoLance
        ? { texto: "VENDIDO", cor: "#4CAF50" }
        : { texto: "NÃO VENDIDO", cor: "#FF826F" };
    },
  },
  methods: {
    async onFinishCountDown(finalizar) {
      if (finalizar) {
        this.countStatus = "Homologando...";
      }
    },
    async onEventNewLance(loteinfo) {
      if (this.lote.loteId === loteinfo.loteId) {
        this.lote.info = loteinfo;
        this.countStatus = null;
      }
    },
  },
  filters: {
    formatMoeda(val) {
      if (!val) return "";
      return val.toLocaleString("pt-br", { minimumFractionDigits: 0 });
    },
  },
  async created() {
    this.$signalR.$on("new-lance", this.onEventNewLance);
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}

.slide-leave-active,
.slide-enter-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.slide-enter {
  opacity: 0;
}

.slide-leave-to {
  opacity: 0;
}

.content .text--secondary {
  font-size: 12px;
}

.text--secondary {
  font-size: 12px;
}

.content .font-weight-black {
  font-size: 20px;
}

.content .font-weight-bold {
  font-size: 16px;
}
</style>
